.LearnUs{
    display: flex;
    justify-content: center;
}
.ourstoryLink {
    text-decoration: none;
}
.LearnUsMain {
    display: flex;
    flex-direction: column;
    width: 1180px;
    /* background-color: green; */
}
.LearnUsFirst{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px 30px;
}
.LearnDivLine{
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
}
.LearnUsFirstTxt1{
    font-weight: 700;
    font-size: 50px;
    color: black;
    padding: 0px 22px;
    margin: 10px 0px 10px 0px;
}
.LearnUsFirstTxt2{
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    color: black;
    margin: 25px 0px 0px 0px;
}
.LearnUsFirstTxt22{
    font-weight: 400;
    font-size: 20px;
    color: black;
    margin: 0px 0px 15px 0px;
}
.LearnUsFirstP{
    font-size: 16px;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
}
.LearnUsFirstP2{
    margin-top: 20px;
    margin-bottom: 20px;
}
.LearnUsSecond{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 30px;
    margin-bottom: 25px;
}
.LearnUsSecondTxt1{
    font-weight: 400;
    font-size: 27px;
    color: black;
    margin: 15px 0px 15px 0px;
}
.LearnUsSecondP{
    margin: 0px 0px 25px 0px;
}
.LearnUsDiv{
    /* background-color: green; */
    max-width:1180px;
    text-align: center;
}
.LearnUsImages{
    width: 100%;
}
.Benjamin{
    text-align: center;
}
.eastText{
    font-size: 22px;
    font-weight:420;
}
.Margindate{
    margin: 25px 0 0 0;
}
.BenjaminDate{ 
    text-align: center;
}
.eastTextDate{
    font-size: 22px;
    font-weight:bold;
}
.BenjaminAddress{ 
    text-align: center;
}
.eastTextAddress{
    font-size: 22px;
    font-weight:420;
}
.jaycristy{
    text-align: center;
}
.jaycristytext{
    font-size: 18px;
    font-weight:bold;
}
.jaycristyP{
    padding: 0;
    margin: 0;
    font-size: 18px;
}
.Support{
    text-align: center;
}
.SupportText{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}
.LastText{
    text-align: center;
}
.LastTextImage{
    margin-top: 0;
    font-size: 20px;
    font-weight: 400;
    /* margin-bottom: 0; */
}
/* .priceabove{
    margin-top: 20px;
} */
@media screen and (max-width: 500px) {
    .LearnUsFirst{
        padding: 10px 15px;
    }
    .LearnUsSecond{
        padding: 10px 15px;
    }
    .LearnUsFirstTxt1{
        font-weight: 700;
        font-size: 35px;
        margin: 1px 0px 1px 0px;
    }
    .LearnUsFirstTxt2{
        font-size: 18px;
        margin: 10px 0px 0px 0px;
    }
    .LearnUsFirstTxt22{
        font-size: 18px;
        margin: 0px 0px 10px 0px;
    }

    .LearnUsFirstP2{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .LearnUsSecondTxt1{
        font-size: 22px;
        margin: 5px 0px 10px 0px;
    }
    .LearnUsSecond{
        margin-bottom: 10px;
    }
    .LearnUsSecondP{
        margin: 0px 0px 10px 0px;
    }
    .LearnUsImages{
        width: 100vw;
    }
    .eastText{
        font-size: 18px;
    }
    .eastTextDate{
        font-size: 18px;
    }
    .eastTextAddress{
        font-size: 16px;
    }
    .jaycristytext{
        font-size: 11px;
    }
    .jaycristyP{
        font-size: 11px;
    }
    .SupportText{
        font-size: 18px;
    }
    .LastTextImage{
        font-size: 12px;
        /* margin-bottom: 0; */
    }
    .endimage{
        margin-top: 10px;
    }
}
.HeaderParent{
    background-color: #c1c1c1;
    display: flex;
    justify-content: center;
}
.HeaderParentChild{
    display: flex;
    justify-content:space-between;
    width: 1180px;
    padding: 15px;
    align-items: center;
}
.HeaderLogo{
    width: 120px;
}
.HeaderParentMenu{
    display: flex;
    align-items: center;
}
.HeaderParentMenuItems{
    padding: 5px 11px;
}
.MenuLink{
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease;
    height: inherit;
    font-size: 13px;
    color: black;
}
.ActiveMenuLink{
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease;
    height: inherit;
    font-size: 13px;
    color: #e93d3d;
}
.MenuLink:hover{
    color: #e93d3d;
}

.OpenMenuImage{
    display: none;
}
.HeaderMobileMenu{
    display: none;
}
@media screen and (min-width: 1175px) {

}
@media screen and (max-width: 890px) {
    .HeaderParentMenuItems{
        display: none;
    }
    .OpenMenuImage{
        display: block;
        margin-right: 15px;
    }
    .HeaderMobileMenu{
        text-align: right;
        padding: 30px;
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 65vw;
        height: 100vh;
        background-color: #767676;
    }
    .MobileMenuItems{
        text-align: left;
        margin-bottom: 15px;
    }
    .openClose{
        cursor: pointer;
    }
    .MobileMenuLink{
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
    }
   
  }
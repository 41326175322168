.SrEliteMainDiv{
    display: flex;
    justify-content: center;
}
.SrEliteContainer{
    width: 1180px;
    margin: 0px 20px 0px 20px;
    /* height: 400px; */
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.SrEliteTitle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 35px 0px 10px 0px;
    border-bottom: 0.8px solid black;
}
.SrELiteTitleText{
    font-size: 50px;
    color: black;
    font-weight: 700;
}
.SrOurHostText{
    font-weight: 500;
    font-size: 30px;
    color:#188bf6;
    padding: 18px 0px 8px 0px;
}
.SrEliteImgText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 35px 0px;
    border-bottom: 0.8px solid black;
}
.SrEliteImgDiv{
    width: 22%;
    text-align: center;
}
.SrEliteImg{
    width: 95%;
}
.SrEliteTextBox{
    width: 70%;
}
.SrEliteTextTitle{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.SrEliteTextContent{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
    margin-top: 12px;
}
.SrEliteTextContent1{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
}
.OURSPEAKERS{
    width: 100vw;
    background-color: #054CC0;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.3em;
    padding: 50px 0px 25px 0px;
    text-align: center;
    letter-spacing: 0;
    color: white;
}
@media screen and (max-width: 700px) {
    .SrEliteTitle{
        padding: 20px 0px 10px 0px;
    }
    .SrELiteTitleText{
        font-size: 30px;
        text-align: center;
    }
    .SrOurHostText{
        font-size: 24px;
        padding: 10px 0px 4px 0px;
    }
    .OURSPEAKERS{
        font-size: 24px;
        padding: 30px 0px 15px 0px;
    }
    .SrEliteImgText{
        flex-direction: column;
    }
    .SrEliteImgDiv{
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .SrEliteImg{
        width: 94%;
    }
    .SrEliteTextBox{
        width: 100%;
    }
}
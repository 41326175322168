.HomeMainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../assets/BackGroundImage.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    /* z-index: 100; */
}
/* Banner Image Start */
.DataBanner {
    padding: 0px 20px 0px 20px;
    max-width: 1140px;
    color: rgba(255, 255, 255, 1);
}
.BannerDivHome {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(13, 27, 142, 0.5);
    padding: 50px 0px;
    /* z-index: -100; */
}
.BannerTitle {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
}
.BannerSubTitle {
    padding-top: 15px;
    font-size: 30px;
    font-weight: 400;
    z-index: 1;
}
.AddressBanner {
    font-size: 20px;
    font-weight: 400;
    padding-top: 2px;
    z-index: 1;
}
.BannerDate {
    font-size: 30px;
    font-weight: 700;
    padding-top: 15px;
    z-index: 1;
}
.BannerDate1 {
    font-size: 30px;
    font-weight: 700;
    padding-top: 4px;
    z-index: 1;
}
.BannerButtonDiv {
    padding: 30px 0px 5px 50px;
    width: fit-content;
    border-radius: 7px;
    /* background-color: #e93d3d; */
}
.BannerBtton {
    padding: 15px 20px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
}
/* Banner Image End */

/* Elite Sponsor Start */
.EliteSponsorMain {
    display: flex;
    max-width: 1180px;
    align-items: center;
    flex-direction: column;
}
.EliteSpnsorHomeText {
    font-size: 23px;
    font-weight: 500;
    color: black;
    margin: 25px 0px 20px 0px;
}
.EliteSpnsorImagesHome {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 10px 0px 15px 0px;
}
.EliteSponsorHomeImageDiv {
    width: 25%;
}
.HomeEliteImg {
    width: 90%;
}
/* Elite Sponsor End */

/* Police home Start */
.HomePolice {
    width: 100%;
    display: flex;
}
.PoliceContent {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
}
.PoliceContentInside {
    width: 65%;
}
.FocusTitle {
    padding: 20px 0px 35px 0px;
    font-weight: 700;
    font-size: 18px;
    color: #e93d3d;
}
.Symposium {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    padding: 5px 0px;
}
.ArmyContent {
    color: #767676;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
    padding: 35px 0px 35px 0px;
}
.OurStoryMainDiv {
    /* background-color: green; */
    display: flex;
    justify-content: center;
}
.ourstoryLink {
    text-decoration: none;
}
.OurStoryButtonDiv {
    background-color: #e93d3d;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    padding: 15px 20px;
    border-radius: 8px;
    color: #ffffff;
}
.PolicePic {
    width: 50%;
    display: flex;
    /* background-color: yellow; */
}
.PoliceImage {
    width: 100%;
    /* object-fit: contain; */
}
/* Police home End */

.HomeOurPartnersImagesDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
}
.HomeOurPartnersImg{
    max-width: 980px;
    display: flex;
}
.HomeTunneltoTowers{
    border-right: 0.5px solid black;
    /* background-color: yellow; */
    text-align: center;
    width: 50%;
}
.HomeTaskForceAntal{
    /* background-color: green; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
}
.HomePartnerImages{
    width: 55%;
}
.HomePartnerImagesTFA{
    width: 38%;
}

/* Exbiting sponsors Start */
.OurESponsorsTitleDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px 0px 25px 0px;
    font-size: 23px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
}
.OurExhibitingSponsorsImagesMain {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
}
.OurExhibitingSponsorsImages {
    display: flex;
    padding: 10px 0px 25px 0px;
    align-items: center;
    max-width: 1180px;
    overflow: hidden;
}
.SponsorCarousel {
    white-space: nowrap;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-animation: scroll 90s linear infinite;
    -webkit-animation: scroll 90s linear infinite;
    animation: scroll 90s linear infinite;
}
@keyframes scroll {
    from {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
.HomeEsponsorImgDiv {
    display: inline-block;
}
.HomeEsponsorImg {
    width: 250px;
}
.newImagesHomePadding{
    padding: 0px 30px;
}
.HomeEsponsorImgAdd {
    width: 135px;
}


.MeetTheNycMainDiv{
    width: 100%;
    background-color: #F7F7F7;
    /* background-color: #e93d3d; */
    padding: 45px 20px 25px 20px;
    display: flex;
    justify-content: center;
}
.MeetTheNycMainData{
    max-width: 1100px;
    padding: 0px 10px 10px 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
    /* background-color: #188bf6; */
}
.MeetTheNycMainDataTitle{
    font-weight: 700;
    font-size: 36px;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: center;
    color: #e93d3d;
}
.MeetTheNycMainDataContent{
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: center;
    color: black;
}


.HomeThreeImagesMain{
    background-color: #F7F7F7;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 35px 15px 45px 15px;
}
.HomeThreeImages{
    max-width: 1180px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.HomeRow1ImagesText{
    font-weight: 700;
    font-size: 18px;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: center;
    color: black;
    padding: 15px 0px 10px;
}
.HomeRowImageTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3em;
    letter-spacing: 0;
    color: #188bf6;
    text-align: center;

    padding: 1px 0px 15px;
}
.HomeThreeImagesDiv1,.HomeThreeImagesDiv2,.HomeThreeImagesDiv3{
    width: 33%;
    text-align: center;
}
.HomeThreeImgs{
    width: 90%;
}


.mapClass{
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 55px 0;
}
.MapClassDiv{
    max-width: 1180px;
    padding: 5px 10px;
    display: flex;
}
.MapClassChilds{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.DianeBarton{
    width: 30%;
    text-align: left;
}
.DianeBartonTxt{
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.SymposiumCoordinator{
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.Email{
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    padding-top: 35px;
}
.EmailAddress{
    font-size: 16px;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 400;
    margin-right: 2px;
    word-break: break-word;
    color: #767676;
}

.GetInTouchDiv{
    width: 25%;
}
.GetInTouch{
    font-size: 25px;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    padding-bottom: 35px;
}
.AssistanceHours{
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.timinghome{ 
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
}
.timingDay{ 
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 35px;
}

.PhoneNumberHome{
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.PhoneNumberData{ 
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
}
.MapImageInsideDiv{
    width: 45%;
    text-align: center;
}
.mapimg{
    width: 95%;
}

@media screen and (max-width: 1185px) {
    .HomeMainDiv {
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: contain;
    }
    .FocusTitle {
        padding: 20px 0px 15px 0px;
    }
    .Symposium {
        font-size: 40px;
        padding: 5px 0px;
    }
    .ArmyContent {
        padding: 25px 0px 25px 0px;
    }
    .OurStoryButtonDiv {
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 800px) {
    .mapClass{
        padding: 40px 0;
    }
    .MapClassChilds{
        display: flex;
        flex-direction: column;
    }
    .DianeBarton{
        width: 100%;
    }
    .DianeBartonTxt{
        font-size: 25px;
    }
    .SymposiumCoordinator{
        font-size: 20px;
    }
    .Email{
        padding-top: 25px;
    }
    
    .GetInTouchDiv{
        padding-top: 15px;
        width: 100%;
    }
    .GetInTouch{
        padding-bottom: 25px;
    }
    .timingDay{ 
        padding-bottom: 25px;
    }
   
    .MapImageInsideDiv{
        width: 100%;
        padding-top: 15px;
    }
    .mapimg{
        width: 100%;
    }
}
@media screen and (max-width: 780px) {
    .FocusTitle {
        padding: 15px 0px 10px 0px;
    }
    .Symposium {
        font-size: 35px;
        padding: 5px 0px;
    }
    .ArmyContent {
        padding: 15px 0px 15px 0px;
    }
    .HomeEsponsorImg {
        width: 190px;
    }
    .HomeEsponsorImgAdd {
        width: 90px;
    }
}

@media screen and (max-width: 700px) {
    /* Banner Image Start */
    .BannerDivHome {
        padding: 35px 0px;
    }
    .BannerTitle {
        font-size: 35px;
        text-align: left;
    }
    .BannerSubTitle {
        padding-top: 20px;
        font-size: 20px;
    }
    .AddressBanner {
        font-size: 16px;
    }
    .BannerDate {
        font-size: 26px;
        padding-top: 15px;
    }
    .BannerDate1 {
        font-size: 26px;
        padding-top: 4px;
    }
    /* Banner Image End */

    /* Elite Sponsor start */
    .EliteSpnsorHomeText {
        font-size: 20px;
        margin: 15px 0px 0px 0px;
    }
    .EliteSpnsorImagesHome {
        margin: 10px 0px 10px 0px;
        flex-direction: column;
    }
    .EliteSponsorHomeImageDiv {
        margin: 0px 0px 10px 0px;
        width: 100%;
    }
    .HomeEliteImg {
        width: 85%;
        object-fit: contain;
    }
    /* Elite Sponsor end */

    /* Police home start */
    .Symposium {
        font-size: 35px;
        padding: 5px 0px;
    }
    .ArmyContent {
        padding: 15px 0px 15px 0px;
    }
    .HomePolice {
        flex-direction: column;
    }
    .PoliceContent {
        width: 100%;
    }
    .PoliceContentInside {
        width: 90%;
    }
    .PolicePic {
        width: 100%;
    }
    .PoliceImage {
        width: 100%;
        object-fit: contain;
    }
    /* exibiting sponsors title */
    .OurESponsorsTitleDiv {
        padding: 15px 0px 20px 0px;
        font-size: 20px;
    }
    /* Police home End */
    .HomeEsponsorImg {
        width: 150px;
    }
    .HomeEsponsorImgAdd {
        width: 75px;
    }
}
@media screen and (max-width: 650px) {
    .HomeThreeImagesMain{
        padding: 35px 20px 20px 20px;
    }
    .HomeThreeImages{
        flex-direction: column;
        /* background-color: aqua; */
    }
    .HomeRow1ImagesText{
        font-size: 20px;
        padding: 10px 0px 5px 0px;
    }
    .HomeRowImageTitle{
        padding: 1px 0px 30px 0px;
    }
    .HomeThreeImagesDiv1,.HomeThreeImagesDiv2,.HomeThreeImagesDiv3{
        text-align: center;
        width: 100%;
    }
    .HomeThreeImgs{
        width: 94%;
    }
    .MeetTheNycMainData{
        max-width: 1100px;
        padding: 0px 10px 10px 10px;
    }
    .MeetTheNycMainDataTitle{
        font-size: 30px;
    }
    .MeetTheNycMainDataContent{
        padding: 5px 10px;
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .HomeOurPartnersImg{
        flex-direction: column;
    }
    .HomeTunneltoTowers{
        border-right: none;
    }
    .HomeTunneltoTowers{
        width: 100%;
    }
    .HomeTaskForceAntal{
        width: 100%;
        padding-top: 10px;
    }
    .HomePartnerImages{
        width: 55%;
    }
    .HomePartnerImagesTFA{
        width: 40%;
    }
}
@media screen and (max-width: 580px) {
    .HomeMainDiv {
        background-attachment: scroll;
        background-position: 0% 0%;
        background-size: auto;
    }
}
@media screen and (max-width: 392px) {
    .BannerDivHome {
        padding: 25px 0px;
    }
    .BannerTitle {
        font-size: 30px;
    }
    .BannerSubTitle {
        padding-top: 15px;
        font-size: 16px;
    }
    .AddressBanner {
        font-size: 14px;
    }
    .BannerDate {
        font-size: 18px;
        padding-top: 10px;
    }
    .BannerDate1 {
        font-size: 18px;
        padding-top: 4px;
    }
    .BannerButtonDiv {
        margin: 20px 0px 5px 0px;
    }
    .BannerBtton {
        padding: 10px 15px;
        font-size: 14px;
    }
}
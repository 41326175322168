.FParentDiv{
    /* background-color: green; */
}
.FGrayDiv{
    background-color: #767676;
    padding: 35px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.ContactUSDetails{
    text-align: center;
    padding: 5px 15px;
    color: #ffffff;
    font-size: 16px;
}
.ContactUSDetailsTitle{
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:3px ;
}
.ContactUsImageIcons{
    width: 16px;
    height: 16px;
    margin-right:3px ;
    /* background-color: green; */
}
.ContactUsImage{
    width: 100%;
    object-fit: contain;
}
.FRedDiv{
    background-color: #e93d3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 15px;
}
.FATCImg{
    width: 100px;
}
.FooterText{
    margin-top:0.83em;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}
@media screen and (max-width: 800px) {
    .FGrayDiv{
        flex-direction: column;
        padding: 35px 0px 25px 0px;
    }
    .ContactUSDetails{
        margin-bottom: 10px;
        font-size: 14px;
    }
    .FooterText{
        font-size: 16px;
    }
  }
.EliteMainDiv{
    display: flex;
    justify-content: center;
}
.EliteContainer{
    width: 1180px;
    margin: 0px 20px 0px 20px;
    /* height: 400px; */
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.EliteTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 35px 0px 10px 0px;
    border-bottom: 0.8px solid black;
}
.ELiteTitleText{
    font-size: 50px;
    color: black;
    font-weight: 700;
}
.EliteImgText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 20px 0px;
    border-bottom: 0.8px solid black;
}
.EliteImgDiv{
    width: 30%;
}
.EliteImg{
    width: 95%;
}
.EliteTextBox{
    width: 70%;
}
.EliteTextTitle{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.EliteTextContent{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
}
.EliteTextLink{
    padding-left:5px ;
}
@media screen and (max-width: 700px) {
    .EliteTitle{
        padding: 20px 0px 10px 0px;
    }
    .ELiteTitleText{
        font-size: 30px;
        text-align: center;
    }
    .EliteImgText{
        flex-direction: column;
    }
    .EliteImgDiv{
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .EliteImg{
        width: 94%;
    }
    .EliteTextBox{
        width: 100%;
    }
}
.AboutUs{
    display: flex;
    justify-content: center;
}
.AboutUsMain {
    display: flex;
    flex-direction: column;
    width: 1180px;
    /* background-color: green; */
}
.AvoutUsFirst{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px 30px;
}
.FirstDivLine{
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 5px;
}
.AvoutUsFirstTxt1{
    font-weight: 700;
    font-size: 50px;
    color: black;
    padding: 0px 22px;
    margin: 10px 0px 10px 0px;
}
.AvoutUsFirstTxt2{
    font-weight: 400;
    font-size: 27px;
    color: black;
    margin: 25px 0px 15px 0px;
}
.AvoutUsFirstP{
    font-size: 16px;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
}
.AvoutUsFirstP2{
    margin-top: 20px;
    margin-bottom: 20px;
}
.AvoutUsSecond{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 30px;
    margin-bottom: 25px;
}
.AvoutUsSecondTxt1{
    font-weight: 400;
    font-size: 27px;
    color: black;
    margin: 15px 0px 15px 0px;
}
.AvoutUsSecondP{
    margin: 0px 0px 25px 0px;
}
.AboutUsDiv{
    /* background-color: green; */
    max-width:1180px;
    text-align: center;
}
.AboutUsImages{
    width: 80%;
}
@media screen and (max-width: 500px) {
    .AvoutUsFirst{
        padding: 10px 15px;
    }
    .AvoutUsSecond{
        padding: 10px 15px;
    }
    .AvoutUsFirstTxt1{
        font-weight: 700;
        font-size: 35px;
        margin: 1px 0px 1px 0px;
    }
    .AvoutUsFirstTxt2{
        font-size: 22px;
        margin: 10px 0px 10px 0px;
    }
    .AvoutUsFirstP2{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .AvoutUsSecondTxt1{
        font-size: 22px;
        margin: 5px 0px 10px 0px;
    }
    .AvoutUsSecond{
        margin-bottom: 10px;
    }
    .AvoutUsSecondP{
        margin: 0px 0px 10px 0px;
    }
    .AboutUsImages{
        width: 100%;
    }
}
.EsEliteMainDiv{
    display: flex;
    justify-content: center;
}
.EsEliteContainer{
    width: 1180px;
    margin: 0px 20px 0px 20px;
    /* height: 400px; */
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.EsEliteTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 35px 0px 10px 0px;
    border-bottom: 0.8px solid black;
}
.EsELiteTitleText{
    font-size: 50px;
    color: black;
    font-weight: 700;
}
.EsEliteImgText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
    border-bottom: 0.8px solid black;
}
.EsEliteImgDiv{
    width: 22%;
    text-align: center;
}
.EsEliteImg{
    width: 90%;
}
.EsEliteImg1{
    width: 50%;
}
.EsEliteTextBox{
    width: 76%;
}
.EsEliteTextTitle{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.EsEliteTextContent{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
}
.EsEliteTextLink{
    padding-left:5px ;
}
@media screen and (max-width: 700px) {
    .EsEliteTitle{
        padding: 20px 0px 10px 0px;
    }
    .EsELiteTitleText{
        font-size: 30px;
        text-align: center;
    }
    .EsEliteImgText{
        flex-direction: column;
    }
    .EsEliteImgDiv{
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .EsEliteImg{
        width: 94%;
    }
    .EsEliteImg1{
        width: 60%;
    }
    .EsEliteTextBox{
        width: 98%;
        /* margin-left: 15px;
        margin-right: 15px; */
    }
}
.GalaryFather{
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.GalaryMain{
    display: flex;
    flex-direction: column;
    width: 1180px;
    padding: 0px 20px 0px 20px;
    /* background-color: green; */
}
.GalarayHeader{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
    margin-bottom: 35px;
    /* background-color: yellow; */
}
.GalarayHeaderTitle{
    font-size: 50px;
    font-weight: 700;
}
.GalarayHeaderSubTitle{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: 0;
    padding-top: 4px;
}

.GalaryTwoImages{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.8px solid black;
    padding: 0px 0px 10px 0px;
}
.GalaryRow1ImagesDiv1{
    text-align: left;
    width: 50%;
}
.GalaryRow1ImagesDiv2{
    text-align: right;
    width: 50%;
}
.GalaryRow1Images{
    width: 96%;
}
.GalaryRow1ImagesText{
    text-align: center;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: center;
    font-size: 14px;
    padding: 5px 0px 15px 0px;
    color: black;
}
.GalaryThreeImages{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px 0px 15px 0px;
    border-bottom: 0.8px solid black;
    margin-bottom: 15px;
}
.GalaryThreeImagesDiv1{
    width: 33%;
    text-align: left;
}
.GalaryThreeImagesDiv2{
    width: 33%;
    text-align: center;
}
.GalaryThreeImagesDiv3{
    width: 33%;
    text-align: right;
    /* background-color: antiquewhite; */
}
.GalaryThreeImgs{
    width: 95%;
}
@media screen and (max-width: 650px) {
    .GalarayHeader{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .GalarayHeaderTitle{
        text-align: center;
        font-size: 35px;
    }
    .GalaryTwoImages{
        flex-direction: column;
        padding: 0px 0px 2px 0px;
    }
    .GalaryRow1ImagesDiv1{
        text-align: center;
        width: 100%;
    }
    .GalaryRow1ImagesDiv2{
        text-align: center;
        width: 100%;
    }
    .GalarayHeaderSubTitle{
        font-size: 18px;
    }
    .GalaryThreeImages{
        display: flex;
        flex-direction: column;
        margin-bottom: 8%;
        padding: 15px 0px 0px 0px;
        border: none;
        margin-bottom: 1px;
    }
    .GalaryThreeImagesDiv1{
        width: 100%;
        text-align: center;
    }
    .GalaryThreeImagesDiv2{
        width: 100%;
        text-align: center;
    }
    .GalaryThreeImagesDiv3{
        width: 100%;
        text-align: center;
    }
}

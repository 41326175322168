.MainPartners{
    display: flex;
    justify-content: center;
}
.Partners{
    width: 1180px;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 20px;
    /* background-color: yellow; */
}
.PartnersTitle{
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    border-bottom: 0.8px solid;
    padding: 40px 0px 15px 0px;
}
.PartnerFirstDiv{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
    padding: 30px 0px;
    border-bottom: 0.8px solid black;
}
.PartnerImageLinkDiv1{
    width: 30%;
    padding-left: 20px;
    text-align: center;
}
.partnerImage1{
    width: 75%;
}
.partnerImage1T{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 50%;
}
.PartnerTitleAndContent1{
    text-align: left;
    padding: 0px 20px 0px 0px;
    width: 65%;
    /* background-color: burlywood; */
}
.PartnerTitleTxt{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.PartnerTitleTxt2{
    font-size: 14px;
    font-weight: 400;
    color: black;
    line-height: 1.7em;
    text-transform: none;
    letter-spacing: 0;
}
.PartnerTitleTxtMargin{
    margin-bottom: 18px;
}
.PartnerFirstDiv2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
}
@media screen and (max-width: 800px) {
    .PartnersTitle{
        font-size: 35px;
        padding: 30px 0px 15px 0px;
    }
    .PartnerTitleTxt{
        font-size: 18px;
    }
    .PartnerFirstDiv{
        flex-direction: column;
        justify-content: center;
        padding: 20px 0px;

    }
    .PartnerFirstDiv2{
        width: 100%;
        flex-direction: column;
        padding: 20px 0px;
    }
    .PartnerTitleTxtMargin{
        margin-bottom: 12px;
    }
    .PartnerImageLinkDiv1{
        width: 95%;
        padding-left: 10px;
    }
    .partnerImage1{
        width: 70%;
    }
    
    .PartnerTitleAndContent1{
        text-align: left;
        padding: 0px 20px 0px 20px;
        width: 95%;
    }
}
.EventEliteMainDiv{
    display: flex;
    justify-content: center;
}
.EventEliteContainer{
    width: 1180px;
    margin: 0px 20px 0px 20px;
    /* height: 400px; */
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;

}
.OurStoryMainDiv1 {
    /* background-color: green; */
    display: flex;
    justify-content: center;
}
.OurStoryButtonDiv1 {
    background-color: #e93d3d;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 15px;
    border-radius: 8px;
    color: #ffffff;
}
.ourstoryLink1 {
    text-decoration: none;
}
.EventEliteTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 35px 0px 25px 0px;
    border-bottom: 0.8px solid black;
}
.EventELiteTitleText{
    font-size: 50px;
    color: black;
    font-weight: 700;
}
.EventEliteImgText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px 50px 0px;
    border-bottom: 0.8px solid black;
}
.EventEliteImgDiv{
    width: 30%;
    text-align: center;
}
.EventEliteImg1{
    width: 50%;
}
.EventEliteTextBox{
    width: 70%;
}
.EventEliteTextTitle{
    font-size: 20px;
    font-weight: 700;
    color: black;
}
.EventEliteTextContentTown, .EventEliteTextContentFullAddress{
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
}
.EventDateText,.EventTimeText{
    padding-left: 25px;
    font-weight: 700;
    font-size: 23px;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
}
.EventLinkBook{
    border-radius: 8px;
    margin-top: 8px;
    background-color: #e93d3d;
    width: fit-content;
}
.EventLinkBookHref{
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0;
    text-transform: none;
    text-shadow: 0 0 0 transparent;
    font-weight: 700;
}
.EventLinkBookText{
    padding: 11px 20px;
}
@media screen and (max-width: 960px) {
    .EventEliteTitle{
        text-align: left;
        padding: 20px 0px 15px 0px;
    }
    .EventELiteTitleText{
        font-size: 35px;
        color: black;
        font-weight: 700;
    }
    .EventEliteImgText{
        flex-direction: column;
        padding: 30px 0px 30px 0px;
    }
    .EventEliteImgDiv{
        width: 100%;
        text-align: center;
    }
    .EventEliteTextBox{
        width: 100%;
        margin-top: 35px;
    }
    .EventEliteTextTitle{
        padding-left: 25px;
        font-size: 20px;
    }
    .EventEliteTextContentTown, .EventEliteTextContentFullAddress{
        padding-left: 25px;
        font-size: 18px;
        text-align: left;
    }
    .EventDateText,.EventTimeText{
        padding-left: 25px;
        font-size: 18px;
        text-align: left;
    }
    .EventLinkBook{
        margin: 25px;
        margin-top: 10px;
    }
    .EventLinkBookHref{
        font-size: 18px;
    }
    .EventLinkBookText{
        padding: 8px 17px;
    }
}